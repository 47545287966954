import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

export const createPost = ({ data }) => {
  return axios.post(`/posts`, data);
};

createPost.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
};

export const useCreatePost = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();
  return useMutation({
    onMutate: async (newPost) => {
      await queryClient.cancelQueries('posts');

      const previousPosts = queryClient.getQueryData('posts');

      queryClient.setQueryData('posts', [...(previousPosts || []), newPost.data]);

      return { previousPosts };
    },
    onError: (_, __, context) => {
      if (context?.previousPosts) {
        queryClient.setQueryData('posts', context.previousPosts);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('posts');
      addNotification({
        type: 'success',
        title: 'Post Created',
      });
    },
    ...config,
    mutationFn: createPost,
  });
};

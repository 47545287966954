import { Button, FullScreenSpinner } from 'components/Elements';
import { Form, FormDrawer, InputField, TextAreaField, UploadField } from 'components/Form';
import { Authorization, ROLES } from 'lib/authorization';
import { PencilIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import { useState } from 'react';
import * as z from 'zod';
import Swal from 'sweetalert2';

import { usePost } from '../api/getPost';
import { useUpdatePostImage } from '../api/updatePostImage';

export const UpdatePostImage = ({ id }) => {
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const postQuery = usePost({ id });
  const updatePostMutation = useUpdatePostImage();

  if (updatePostMutation.isLoading) return <FullScreenSpinner />;

  return (
    <Authorization allowedRoles={[ROLES.ADMIN]}>
      <FormDrawer
        isDone={updatePostMutation.isSuccess}
        triggerButton={
          <Button startIcon={<PencilIcon className="h-4 w-4" />} size="sm">
            Edit Post Cover
          </Button>
        }
        title=""
        submitButton={
          <Button
            form="update-post"
            type="submit"
            size="sm"
            isLoading={updatePostMutation.isLoading}
          >
            Submit
          </Button>
        }
      >
        <Form
          id="update-post"
          onSubmit={async (values) => {
            const bodyFormData = new FormData();
            bodyFormData.append('image', image);
            await updatePostMutation.mutateAsync({ data: bodyFormData, id });
            setPreview(null);
          }}
          options={{
            defaultValues: {
              image: postQuery.data?.image,
            },
          }}
        >
          {({ register, formState, watch, setValue }) => (
            <>
              <UploadField
                title="Edit Cover Image"
                subTitle="Please use the recommended dimensions to balance out the image."
                error={formState.errors['image']}
                button={
                  <button
                    type="button"
                    onClick={() => {
                      setPreview(null);
                    }}
                    className="text-red-500 cursor-pointer"
                  >
                    Remove
                  </button>
                }
                x={740}
                y={384}
                onChange={(e) => {
                  // set maximum size of file to 250 KB, only allow jpg and png and image size must be 740 x 384
                  if (e.target.files[0].size > 250000) {
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: 'File size is too large!',
                    });
                    return;
                  }
                  if (
                    e.target.files[0].type !== 'image/jpeg' &&
                    e.target.files[0].type !== 'image/png'
                  ) {
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: 'File type is not supported!',
                    });
                    return;
                  }

                  // image must be 740 x 384
                  const img = new Image();
                  img.src = URL.createObjectURL(e.target.files[0]);
                  img.onload = function () {
                    const width = img.naturalWidth,
                      height = img.naturalHeight;
                    URL.revokeObjectURL(img.src);
                    if (width !== 740 || height !== 384) {
                      Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Image size must be (740 x 384)!',
                      });
                      e.target.value = '';
                      setPreview(null);
                      setImage(null);
                      return;
                    }
                  };

                  setImage(e.target.files[0]);
                  setPreview(URL.createObjectURL(e.target.files[0]));
                }}
                preview={preview}
              />
            </>
          )}
        </Form>
      </FormDrawer>
    </Authorization>
  );
};

UpdatePostImage.propTypes = {
  id: PropTypes.string.isRequired,
};

import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

export const deletePostAttachment = ({ id, postId }) => {
  return axios.delete(`/posts/${postId}/attachments/${id}`);
};

deletePostAttachment.propTypes = {
  postId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export const useDeletePostAttachment = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (deletedAttachment) => {
      await queryClient.cancelQueries('posts');

      const previousPosts = queryClient.getQueryData('posts');

      queryClient.setQueryData(
        'posts',
        previousPosts?.map((post) => {
          if (post.id === deletedAttachment.postId) {
            return {
              ...post,
              attachments: post.attachments.filter(
                (attachment) => attachment.id !== deletedAttachment.id
              ),
            };
          }
          return post;
        })
      );

      return { previousPosts };
    },
    onError: (_, __, context) => {
      if (context?.previousPosts) {
        queryClient.setQueryData('posts', context.previousPosts);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('posts');
      queryClient.invalidateQueries('post');
      addNotification({
        type: 'success',
        title: 'Attachment Deleted',
      });
    },
    ...config,
    mutationFn: deletePostAttachment,
  });
};

import { Button, ConfirmationDialog, FullScreenSpinner } from 'components/Elements';
import { Authorization, ROLES } from 'lib/authorization';
import { TrashIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';

import { useDeletePostAttachment } from '../api/deletePostAttachment';

export const DeletePostAttachment = ({ id, postId }) => {
  const deletePostAttachmentMutation = useDeletePostAttachment();

  if (deletePostAttachmentMutation.isLoading) return <FullScreenSpinner />;

  return (
    <Authorization allowedRoles={[ROLES.ADMIN]}>
      <ConfirmationDialog
        icon="danger"
        title="Remove Attachment"
        body="Are you sure you want to remove this attachment?"
        triggerButton={
          <Button variant="danger" startIcon={<TrashIcon className="h-4 w-4" />}>
            Remove
          </Button>
        }
        confirmButton={
          <Button
            isLoading={deletePostAttachmentMutation.isLoading}
            type="button"
            className="bg-red-600"
            onClick={async () => await deletePostAttachmentMutation.mutateAsync({ id, postId })}
          >
            Remove
          </Button>
        }
      />
    </Authorization>
  );
};

DeletePostAttachment.propTypes = {
  id: PropTypes.string.isRequired,
  postId: PropTypes.string.isRequired,
};

import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

export const deletePost = ({ id }) => {
  return axios.delete(`/posts/${id}`);
};

deletePost.propTypes = {
  id: PropTypes.string.isRequired,
};

export const useDeletePost = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (deletedPost) => {
      await queryClient.cancelQueries('posts');

      const previousPosts = queryClient.getQueryData('posts');

      queryClient.setQueryData(
        'posts',
        previousPosts?.filter((post) => post.id !== deletedPost.id)
      );

      return { previousPosts };
    },
    onError: (_, __, context) => {
      if (context?.previousPosts) {
        queryClient.setQueryData('posts', context.previousPosts);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('posts');
      addNotification({
        type: 'success',
        title: 'Post Deleted',
      });
    },
    ...config,
    mutationFn: deletePost,
  });
};

import { Table, Spinner, Link } from 'components/Elements';
import { formatDate } from 'utils/format';

import { usePosts } from '../api/getPosts';

import { DeletePost } from './DeletePost';

export const PostsList = () => {
  const postsQuery = usePosts();

  if (postsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!postsQuery.data) return null;

  return (
    <Table
      data={postsQuery.data}
      entries="News Posts"
      columns={[
        {
          title: 'Title',
          field: 'title',
          Cell({ entry: { title, createdAt, _id } }) {
            return (
              <Link className="flex flex-col" to={`./${_id}`}>
                <span className="text-gray-800 dark:text-gray-400 hover:text-green-600 dark:hover:text-gray-300">
                  {title && title.split(' ').slice(0, 10).join(' ')}
                </span>
                <span className="text-gray-400 dark:text-gray-600 text-xs">
                  {formatDate(createdAt)}
                </span>
              </Link>
            );
          },
        },
        {
          title: 'Status',
          field: 'draft',
          Cell({ entry: { draft } }) {
            return (
              <>
                {draft === true && (
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                    Draft
                  </span>
                )}
                {draft === false && (
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    Published
                  </span>
                )}
              </>
            );
          },
        },
        {
          title: '',
          field: '_id',
          Cell({ entry: { _id } }) {
            return <DeletePost id={_id} />;
          },
        },
      ]}
    />
  );
};

import clsx from 'clsx';
import PropTypes from 'prop-types';
import * as React from 'react';

const variants = {
  primary: 'text-blue-900',
  inverse: 'text-gray-100',
  dark: 'text-gray-800',
  danger: 'text-red-500',
};

const cases = {
  upper: 'uppercase',
  lower: 'lowercase',
  capital: 'capitalize',
};

const sizes = {
  xs: 'text-xs',
  sm: 'p-1 text-sm',
  md: 'p-1 text-sm md:text-md',
  lg: 'p-1 text-md md:text-lg',
  xl: 'p-1 text-xl md:text-3xl',
};

export const Typography = ({
  className = '',
  variant = 'primary',
  fontCase = 'lowercase',
  size = 'md',
  children,
  ...props
}) => {
  return (
    <div
      className={clsx('', variants[variant], sizes[size], cases[fontCase], className)}
      {...props}
    >
      {children}
    </div>
  );
};

Typography.displayName = 'Typography';

Typography.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  fontCase: PropTypes.oneOf(['uppercase', 'lowercase', 'capitalize']),
  variant: PropTypes.oneOf(['primary', 'inverse', 'danger']),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  children: PropTypes.node,
};

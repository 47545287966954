import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getPost = ({ id }) => {
  return axios.get(`/posts/${id}`);
};

export const usePost = ({ id, config }) => {
  return useQuery({
    ...config,
    queryKey: ['post', id],
    queryFn: () => getPost({ id }),
  });
};

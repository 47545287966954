import { Spinner } from 'components/Elements';
import { ContentLayout } from 'components/Layout';
import { useAuth } from 'lib/auth';
import { Link as NavLink } from 'react-router-dom';
import { useStatistics } from '../api/getStatistics';
import { useState } from 'react';
import { DataOverviewChart } from '../components/DataOverviewChart';
import { usePosts } from 'features/posts';

export const Dashboard = () => {
  const { user } = useAuth();

  const statisticsQuery = useStatistics();

  const postsQuery = usePosts();

  if (statisticsQuery.isLoading || postsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!statisticsQuery.data || !postsQuery.data) return null;

  return (
    <ContentLayout title="Dashboard">
      <div
        className="grid grid-cols-1 lg:grid-cols-3 gap-4"
        style={{
          minHeight: '75vh',
        }}
      >
        <div className="lg:col-span-3">
          <div className="my-2">
            <p className="text-gray-500 text-lg font-bold">Hello 👋🏼 {user.name}</p>
          </div>

          <section className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-4">
            <div className="bg-white rounded-lg shadow-md p-6 text-center">
              <h2 className="text-xl font-bold text-gray-600">Banners</h2>
              <p className="text-4xl font-semibold text-blue-600">{statisticsQuery.data.banners}</p>
            </div>
            <div className="bg-white rounded-lg shadow-md p-6 text-center">
              <h2 className="text-xl font-bold text-gray-600">News</h2>
              <p className="text-4xl font-semibold text-green-600">{statisticsQuery.data.posts}</p>
              {/* Add other news post details here */}
            </div>
            <div className="bg-white rounded-lg shadow-md p-6 text-center">
              <h2 className="text-xl font-bold text-gray-600">Reports</h2>
              <p className="text-4xl font-semibold text-green-600">
                {statisticsQuery.data.reports}
              </p>
              {/* Add other news post details here */}
            </div>
            <div className="bg-white rounded-lg shadow-md p-6 text-center">
              <h2 className="text-xl font-bold text-gray-600">Trainees</h2>
              <p className="text-4xl font-semibold text-green-600">
                {statisticsQuery.data.trainees}
              </p>
              {/* Add other news post details here */}
            </div>
            {/* Add more content sections here */}
          </section>

          {/* Section 3: Recent Activities */}
          {/* <section className="mt-8 mb-8">
            <h2 className="text-xl font-bold text-gray-600 mb-3">Recent Activities</h2>
            <div className="bg-white rounded-lg shadow-md p-6">
              
            </div>
          </section> */}

          {/* <div className="bg-white rounded-lg shadow-md p-6 mb-8 mt-8">
            <div className="flex flex-row items-center justify-between mb-1">
              <span className="text-xs text-gray-700 uppercase">Recent Comments</span>
              <span className="text-gray-400 text-xs">This week</span>
            </div>
            <span className="text-gray-400 text-sm">There are no comments at the moment.</span>
            <hr />
          </div> */}
          {/* <div className="bg-white rounded-lg shadow-md p-6">
            <DataOverviewChart postViewsData={postsQuery.data} />
          </div> */}
        </div>
      </div>
    </ContentLayout>
  );
};

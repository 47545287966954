import { Button, FullScreenSpinner } from 'components/Elements';
import {
  Form,
  FormDrawer,
  InputField,
  SelectField,
  TextAreaField,
  UploadField,
  RegularTextAreaField,
} from 'components/Form';
import { Authorization, ROLES } from 'lib/authorization';
import { PlusIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import * as z from 'zod';
import Swal from 'sweetalert2';

import { useCreatePost } from '../api/createPost';

export const CreatePost = () => {
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const createPostMutation = useCreatePost();

  if (createPostMutation.isLoading) return <FullScreenSpinner />;

  return (
    <Authorization allowedRoles={[ROLES.ADMIN]}>
      <FormDrawer
        isDone={createPostMutation.isSuccess}
        size="md"
        triggerButton={
          <Button size="sm" startIcon={<PlusIcon className="h-4 w-4" />}>
            Create
          </Button>
        }
        title="Create News Post"
        submitButton={
          <Button
            form="create-post"
            type="submit"
            size="sm"
            isLoading={createPostMutation.isLoading}
          >
            Submit
          </Button>
        }
      >
        <Form
          id="create-post"
          onSubmit={async (values) => {
            const { title, content, draft, excerpt } = values;
            const bodyFormData = new FormData();
            bodyFormData.append('image', image);
            bodyFormData.append('title', title);
            bodyFormData.append('content', content);
            bodyFormData.append('draft', draft);
            bodyFormData.append('excerpt', excerpt);
            await createPostMutation.mutateAsync({ data: bodyFormData });
            setPreview(null);
          }}
        >
          {({ register, formState, watch, setValue }) => (
            <>
              <InputField
                label="Title"
                error={formState.errors['title']}
                registration={register('title')}
                caption="(65 characters max)"
                maxLength={65}
              />
              <RegularTextAreaField
                label="Excerpt"
                error={formState.errors['excerpt']}
                registration={register('excerpt')}
                caption="(80 characters max)"
                maxLength={80}
              />
              <TextAreaField
                label="Content"
                error={formState.errors['content']}
                editorContent={watch('content')}
                setValue={setValue}
                value="content"
              />
              <UploadField
                title="Upload Cover Image"
                subTitle="Please use the recommended dimensions to balance out the image."
                error={formState.errors['image']}
                button={
                  <button
                    type="button"
                    onClick={() => {
                      setPreview(null);
                    }}
                    className="text-red-500 cursor-pointer"
                  >
                    Remove
                  </button>
                }
                x={740}
                y={384}
                onChange={(e) => {
                  // set maximum size of file to 250 KB, only allow jpg and png and image size must be 740 x 384
                  if (e.target.files[0].size > 250000) {
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: 'File size is too large!',
                    });
                    return;
                  }
                  if (
                    e.target.files[0].type !== 'image/jpeg' &&
                    e.target.files[0].type !== 'image/png'
                  ) {
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: 'File type is not supported!',
                    });
                    return;
                  }

                  // image must be 740 x 384
                  const img = new Image();
                  img.src = URL.createObjectURL(e.target.files[0]);
                  img.onload = function () {
                    const width = img.naturalWidth,
                      height = img.naturalHeight;
                    URL.revokeObjectURL(img.src);
                    if (width !== 740 || height !== 384) {
                      Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Image size must be (740 x 384)!',
                      });
                      e.target.value = '';
                      setPreview(null);
                      setImage(null);
                      return;
                    }
                  };

                  setImage(e.target.files[0]);
                  setPreview(URL.createObjectURL(e.target.files[0]));
                }}
                preview={preview}
              />
              <SelectField
                label="Status"
                registration={register('draft')}
                options={[
                  { label: 'Draft', value: true },
                  { label: 'Published', value: false },
                ]}
              />
            </>
          )}
        </Form>
      </FormDrawer>
    </Authorization>
  );
};

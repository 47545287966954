import { Spinner, MDPreview } from 'components/Elements';
import { Head } from 'components/Head';
import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';
import { UPLOADS_URL } from 'config';
import { formatDate } from 'utils/format';
import { useParams } from 'react-router-dom';

import { usePost } from '../api/getPost';
import { UpdatePost } from '../components/UpdatePost';
import { AddAttachment } from '../components/AddAttachment';
import { UpdatePostImage } from '../components/UpdatePostImage';
import { MdAttachFile } from 'react-icons/md';
import { DeletePostAttachment } from '../components/DeletePostAttachment';

export const Post = () => {
  const { id } = useParams();
  const postQuery = usePost({ id });

  const routes = [
    {
      name: 'news',
      path: '/app/news',
    },
    {
      name: postQuery.data?.title,
      path: `/app/news/${id}`,
    },
  ];

  if (postQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!postQuery.data) return null;

  return (
    <>
      <Head title={postQuery.data.title} />
      <ContentLayout title={postQuery.data.title} end={<UpdatePost id={id} />}>
        <div className="flex flex-row space-x-2 items-center">
          <span className="text-xs font-bold dark:text-gray-500">
            {formatDate(postQuery.data.createdAt)}
          </span>
          <span>
            {postQuery.data?.draft === true && (
              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                Draft
              </span>
            )}
            {postQuery.data?.draft === false && (
              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                Published
              </span>
            )}
          </span>
        </div>
        <Breadcrumbs routes={routes} />
        <div className="mt-6 flex flex-col space-y-16">
          <div>
            <div className="bg-white dark:bg-gray-600 shadow overflow-hidden sm:rounded-lg mb-2">
              <div className="h-60 overflow-hidden">
                <div className="flex flex-row justify-end items-center m-2">
                  <UpdatePostImage id={id} />
                </div>
                <div className="text-xs bg-gray-200 py-4 p-1 font-bold dark:text-gray-500 w-full">
                  {postQuery.data?.excerpt}
                </div>
                <img
                  src={UPLOADS_URL + postQuery.data.image}
                  alt={postQuery.data.title}
                  className="w-full"
                />
              </div>
              <div className="px-4 py-5 sm:px-6">
                <div className="mt-1 text-sm text-gray-500 dark:text-white">
                  <MDPreview value={postQuery.data.content} />
                </div>
              </div>
            </div>
            <div className="flex justify-end my-3">
              <AddAttachment id={id} />
            </div>
            {postQuery.data?.attachments?.length > 0 && (
              <div className="overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
                    Attachments
                  </h3>
                  <div className="mt-2 text-sm text-gray-500 dark:text-white">
                    <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                      {postQuery.data?.attachments?.map((attachment) => (
                        <li
                          key={attachment._id}
                          className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                        >
                          <a
                            href={UPLOADS_URL + attachment.location}
                            target="_blank"
                            rel="noreferrer"
                            className="w-0 flex-1 flex items-center"
                          >
                            <MdAttachFile className="flex-shrink-0 h-5 w-5 text-gray-400" />
                            <span className="ml-2 flex-1 w-0 truncate">{attachment.title}</span>
                          </a>
                          <DeletePostAttachment id={attachment._id} postId={id} />
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </ContentLayout>
    </>
  );
};

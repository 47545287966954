import { useAuthorization, ROLES } from 'lib/authorization';
import { HomeIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import * as React from 'react';
import { CiViewTimeline } from 'react-icons/ci';
import {
  FileText,
  Users,
  Film,
  Book,
  BookOpen,
  Briefcase,
  AlignJustify,
  List,
  File,
  Circle,
  Folder,
  Layers,
  Archive,
  Link2,
  Disc,
  DownloadCloud,
  Trello,
  Columns,
} from 'react-feather';
import { NavLink } from 'react-router-dom';
import { MdList } from 'react-icons/md';

export const SideNavigation = ({ showLabels = true }) => {
  const { checkAccess } = useAuthorization();
  const navigation = [
    { name: 'Dashboard', to: '.', icon: HomeIcon },

    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Banners',
      to: './banners',
      icon: Book,
      heading: 'Information',
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'News',
      to: './news',
      icon: BookOpen,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Multimedia',
      to: './multimedia',
      icon: Film,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Press Release',
      to: './press-release',
      icon: Briefcase,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Reports',
      to: './reports',
      icon: List,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Timetables',
      to: './timetables',
      icon: CiViewTimeline,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Circulars',
      to: './circulars',
      icon: Folder,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Acts',
      to: './acts',
      icon: FileText,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'EMIS Manual',
      to: './emis-manual',
      icon: Trello,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Other Documents',
      to: './documents',
      icon: Archive,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Registration Guidelines',
      to: './guidelines',
      icon: MdList,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Events',
      to: './events',
      icon: AlignJustify,
      heading: 'Events',
    },

    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Programmes',
      to: './programmes',
      icon: Folder,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Summary Sheets',
      to: './summary-sheets',
      icon: Layers,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Assessment Plan',
      to: './assessment-plan',
      icon: Columns,
      heading: 'Resources',
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Publications',
      to: './publications',
      icon: File,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Counter',
      to: './counter',
      icon: Circle,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Downloads',
      to: './downloads-resources',
      icon: DownloadCloud,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Trainee Registration',
      to: './trainees',
      icon: Users,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Alumni',
      to: './alumni',
      icon: Users,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'UBTEB Links',
      to: './ubteb-links',
      icon: Link2,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Accredited Centres',
      to: './accredited-centres',
      icon: Disc,
    },

    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Partners',
      to: './partners',
      icon: FileText,
      heading: 'People & Opportunities',
    },
    // checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
    //   name: 'Industrial Training',
    //   to: './industrial-training',
    //   icon: FileText,
    // },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'People',
      to: './people',
      icon: Users,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Jobs',
      to: './jobs',
      icon: FileText,
    },
    checkAccess({ allowedRoles: [ROLES.ADMIN] }) && {
      name: 'Tenders',
      to: './tenders',
      icon: FileText,
    },
  ].filter(Boolean);

  return (
    <>
      {navigation.map((item, index) => (
        <div key={index}>
          {item.heading && (
            <p
              key={index}
              className="px-3 py-4 my-2 text-xs flex flex-row font-sm text-blue-300 uppercase tracking-wider"
            >
              <span>{showLabels && item.heading}</span>
            </p>
          )}
          <NavLink
            end={index === 0}
            key={item.name}
            to={item.to}
            className={clsx(
              'text-white  hover:font-black hover:text-blue-600 hover:bg-white',
              'group flex items-center px-2 py-2 text-sm font-medium rounded-md mb-3'
            )}
          >
            <div className="flex flex-col">
              <div className="flex">
                <item.icon
                  className={clsx(
                    'text-white group-hover:text-blue-600 hover:font-black',
                    'mr-4 flex-shrink-0 h-6 w-6'
                  )}
                  aria-hidden="true"
                />
                {showLabels && <span>{item.name}</span>}
              </div>
            </div>
          </NavLink>
        </div>
      ))}
    </>
  );
};

import { Button, FullScreenSpinner } from 'components/Elements';
import { Form, FormDrawer, InputField, DocumentUploadField } from 'components/Form';
import { Authorization, ROLES } from 'lib/authorization';
import { PencilIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import { useState } from 'react';
import * as z from 'zod';
import Swal from 'sweetalert2';

import { useAddAttachment } from '../api/addAttachment';
import { MdAttachFile } from 'react-icons/md';

export const AddAttachment = ({ id }) => {
  const addAttachmentMutation = useAddAttachment();
  const [attachment, setAttachment] = useState('');

  if (addAttachmentMutation.isLoading) return <FullScreenSpinner />;

  return (
    <Authorization allowedRoles={[ROLES.ADMIN]}>
      <FormDrawer
        isDone={addAttachmentMutation.isSuccess}
        size="sm"
        triggerButton={
          <Button startIcon={<MdAttachFile className="h-4 w-4" />} size="sm">
            Add Attachment
          </Button>
        }
        title="Add Attachment"
        submitButton={
          <Button
            form="add-attachment"
            type="submit"
            size="sm"
            isLoading={addAttachmentMutation.isLoading}
          >
            Submit
          </Button>
        }
      >
        <Form
          id="add-attachment"
          onSubmit={async (values) => {
            const { title } = values;
            const bodyFormData = new FormData();
            bodyFormData.append('title', title);
            bodyFormData.append('location', attachment);
            await addAttachmentMutation.mutateAsync({ data: bodyFormData, id });
          }}
        >
          {({ register, formState }) => (
            <>
              <InputField
                label="Title"
                error={formState.errors['title']}
                registration={register('title')}
              />
              <DocumentUploadField
                label="Attachment"
                accept="application/pdf"
                onChange={(e) => {
                  // set maximum size of file to 10 MB
                  if (e.target.files[0].size > 10000000) {
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: 'File size is too large!',
                    });
                    return;
                  }
                  if (e.target.files[0].type !== 'application/pdf') {
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: 'File type is not supported!',
                    });
                    return;
                  }
                  setAttachment(e.target.files[0]);
                }}
              />
            </>
          )}
        </Form>
      </FormDrawer>
    </Authorization>
  );
};

AddAttachment.propTypes = {
  id: PropTypes.string.isRequired,
};

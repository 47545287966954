import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import { useMutation } from 'react-query';

export const addAttachment = ({ data, id }) => {
  return axios.patch(`/posts/${id}/attachments`, data);
};

export const useAddAttachment = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (newAttachment) => {
      await queryClient.cancelQueries('posts');

      const previousPosts = queryClient.getQueryData('posts');

      queryClient.setQueryData(
        'posts',
        previousPosts?.map((post) => {
          if (post.id === newAttachment.postId) {
            return {
              ...post,
              attachments: [...post.attachments, newAttachment],
            };
          }
          return post;
        })
      );

      return { previousPosts };
    },
    onError: (_, __, context) => {
      if (context?.previousPosts) {
        queryClient.setQueryData('posts', context.previousPosts);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries('posts');
      queryClient.invalidateQueries('post');
      addNotification({
        type: 'success',
        title: 'Attachment Added',
      });
    },
    ...config,
    mutationFn: addAttachment,
  });
};

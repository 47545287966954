import { axios } from 'lib/axios';
import { useQuery } from 'react-query';

export const getStatistics = () => {
  return axios.get('/statistics');
};

export const useStatistics = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ['statistics'],
    queryFn: () => getStatistics(),
  });
};

import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';

import { CreatePost } from '../components/CreatePost';
import { PostsList } from '../components/PostsList';

export const Posts = () => {
  const routes = [
    {
      name: 'News',
      path: '/app/news',
    },
  ];
  return (
    <ContentLayout title="News" end={<CreatePost />}>
      <Breadcrumbs routes={routes} />
      <div className="mt-4">
        <PostsList />
      </div>
    </ContentLayout>
  );
};

import { Menu } from 'react-feather';
import { Logo } from './Logo';
import { SideNavigation } from './SideNavigation';
import { useState } from 'react';
import clsx from 'clsx';

export const Sidebar = () => {
  const [showLabels, setShowLabels] = useState(true);
  return (
    <div className="hidden md:flex md:flex-shrink-0">
      <div className={`flex flex-col transition-all ${showLabels ? 'w-60' : 'w-16'}`}>
        <div className="flex flex-col h-0 flex-1">
          <div className="flex items-center h-16 flex-shrink-0 justify-between px-4">
            {showLabels ? <Logo /> : null}
            <button onClick={() => setShowLabels(!showLabels)}>
              <Menu className="h-10 text-gray-800" />
            </button>
          </div>
          <div className="flex-1 flex flex-col overflow-y-auto">
            <nav
              className={clsx('flex-1 px-2 py-4 space-y-1', {
                'bg-blue-900': showLabels,
                'bg-blue-900 w-16': !showLabels,
              })}
            >
              <SideNavigation showLabels={showLabels} />
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

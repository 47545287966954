import { usePreferences } from 'features/settings/api/getPreferences';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

export const Head = ({ title = '', description = '' }) => {
  const preferenceQuery = usePreferences();

  if (preferenceQuery.isLoading) {
    return <></>;
  }
  if (!preferenceQuery.data) return null;

  return (
    <Helmet
      title={title ? `${title} | ${preferenceQuery.data?.website?.name}` : undefined}
      defaultTitle="CMS"
    >
      <meta
        name="description"
        content={preferenceQuery.data?.website?.description || description}
      />
    </Helmet>
  );
};

Head.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

import { Button, FullScreenSpinner } from 'components/Elements';
import {
  Form,
  FormDrawer,
  InputField,
  SelectField,
  TextAreaField,
  RegularTextAreaField,
} from 'components/Form';
import { Authorization, ROLES } from 'lib/authorization';
import { PencilIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import { useState } from 'react';
import * as z from 'zod';

import { usePost } from '../api/getPost';
import { useUpdatePost } from '../api/updatePost';

export const UpdatePost = ({ id }) => {
  const postQuery = usePost({ id });
  const updatePostMutation = useUpdatePost();

  if (updatePostMutation.isLoading) return <FullScreenSpinner />;

  return (
    <Authorization allowedRoles={[ROLES.ADMIN]}>
      <FormDrawer
        isDone={updatePostMutation.isSuccess}
        triggerButton={
          <Button startIcon={<PencilIcon className="h-4 w-4" />} size="sm">
            Edit details
          </Button>
        }
        title="Edit Post Details"
        submitButton={
          <Button
            form="update-post"
            type="submit"
            size="sm"
            isLoading={updatePostMutation.isLoading}
          >
            Submit
          </Button>
        }
      >
        <Form
          id="update-post"
          onSubmit={async (values) => {
            await updatePostMutation.mutateAsync({ data: values, id });
          }}
          options={{
            defaultValues: {
              title: postQuery.data?.title,
              content: postQuery.data?.content,
              excerpt: postQuery.data?.excerpt,
              draft: postQuery.data?.draft,
            },
          }}
        >
          {({ register, formState, watch, setValue }) => (
            <>
              <InputField
                label="Title"
                error={formState.errors['title']}
                registration={register('title')}
                caption="(65 characters max)"
                maxLength={65}
              />
              <RegularTextAreaField
                label="Excerpt"
                error={formState.errors['excerpt']}
                registration={register('excerpt')}
                caption="(80 characters max)"
                maxLength={80}
              />
              <TextAreaField
                label="Content"
                error={formState.errors['content']}
                editorContent={watch('content')}
                setValue={setValue}
                value="content"
              />
              <SelectField
                label="Status"
                registration={register('draft')}
                options={[
                  { label: 'Draft', value: true },
                  { label: 'Published', value: false },
                ]}
              />
            </>
          )}
        </Form>
      </FormDrawer>
    </Authorization>
  );
};

UpdatePost.propTypes = {
  id: PropTypes.string.isRequired,
};

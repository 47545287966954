import { axios } from 'lib/axios';
import { queryClient } from 'lib/react-query';
import { useNotificationStore } from 'stores/notifications';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';

export const updatePost = ({ data, id }) => {
  return axios.patch(`/posts/${id}`, data);
};

export const useUpdatePost = ({ config } = {}) => {
  const { addNotification } = useNotificationStore();

  return useMutation({
    onMutate: async (updatingPost) => {
      await queryClient.cancelQueries(['post', updatingPost?.id]);

      const previousPost = queryClient.getQueryData[('post', updatingPost?.id)];

      queryClient.setQueryData(['post', updatingPost?.id], {
        ...previousPost,
        ...updatingPost.data,
        id: updatingPost.id,
      });

      return { previousPost };
    },
    onError: (_, __, context) => {
      if (context?.previousPost) {
        queryClient.setQueryData(['post', context.previousPost.id], context.previousPost);
      }
    },
    onSuccess: (data) => {
      queryClient.refetchQueries(['post', data._id]);
      addNotification({
        type: 'success',
        title: 'Post Updated',
      });
    },
    ...config,
    mutationFn: updatePost,
  });
};

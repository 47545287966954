import tawkLogo from 'assets/tawk-site.png';
import { MenuAlt2Icon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';
import * as React from 'react';
import { MobileSidebar } from './components/MobileSidebar';
import { Sidebar } from './components/Sidebar';
import { UserNavigation } from './components/UserNavigation';
import { useAuth } from 'lib/auth';

export const MainLayout = ({ children }) => {
  const { user } = useAuth();
  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return (
    <div className="h-screen flex overflow-hidden bg-white">
      <MobileSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <Sidebar />
      <div className="bg-gray-100 flex flex-col w-0 flex-1 overflow-hidden">
        <div className="relative z-10 items-center flex-shrink-0 flex h-16">
          <div className="px-10 text-xs text-gray-700 font-bold truncate overflow-hidden">
            <span className="text-green-600">{user.name}</span> currently logged in.
          </div>
          <button
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 px-4 flex justify-end">
            <div className="ml-4 flex items-center md:ml-6">
              <a href="https://dashboard.tawk.to/login" target="_blank">
                <div className="relative">
                  <img src={tawkLogo} alt="tawk.io" className="h-8 w-8" />
                </div>
              </a>
            </div>
            <div className="ml-4 flex items-center md:ml-6">
              <UserNavigation />
            </div>
          </div>
        </div>
        <main className="flex-1 relative overflow-y-auto focus:outline-none dark:bg-gray-800">
          {children}
          <div className="px-10 text-xs text-gray-800 truncate overflow-hidden">
            All rights reserved © UBTEB {currentYear}, Maintained by{' '}
            <a
              href="https://jast-tech.com"
              className="text-blue-500 font-black"
              target="_blank"
              rel="noreferrer"
            >
              Jast Tech Ltd
            </a>
          </div>
        </main>
      </div>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
